<template>
<div class='live_main'>
  <header-base></header-base>

  <div class="live_msg">
    <div class="top">
      <div class="user">
        <img :src="liveUserMes.icon" alt="">
        <h3>
          <p class="name">{{liveUserMes.nickName}}</p>
          <p class="fensi">粉丝数：{{liveUserMes.numberOfFans}}人</p>
        </h3>
      </div>

      <div class="user_setting">
        <el-button @click="userGuanZhu(liveUserMes)">{{userConcern==1?'取消关注':'关 注'}}</el-button>
        <el-button>店铺</el-button>
        <!-- <span class="look"><el-icon><user-filled /></el-icon></span> -->
        <span class="star" @click="linkStore" v-if="this.likeOrNot==0">
          <el-icon><StarFilled /></el-icon>
        </span>
        <span class="nostar" @click="linkStore" v-else>
          <el-icon color="#8F4B1C"><StarFilled /></el-icon>
        </span>
        <!-- <el-icon><share /></el-icon> -->
      </div>
    </div>

    <!-- <div class="video">
      <div class="title_left">
        <el-button>进入直播间</el-button>
      </div>

      <div class="title_right">
        <div class="title_msg">
          欢迎大家来到直播间！直播间 内严禁出现违法违规、色情低 俗、吸烟酗酒等内容，若有规、 行为请及时举报。
        </div>
        <div class="comment_body">
          <ul class="test_ul">
            <li class="test_li">
              <span class="user_name">张三进入直播间</span>
            </li>
            <li class="test_li">
              <span class="user_name">李四：</span>欢迎大帅哥进入直播间
            </li>
            <li class="test_li">
              <span class="user_name">王五：</span>欢迎大帅哥进入直播间欢迎大帅哥进入直播间欢迎大帅哥进入直播间欢迎大帅哥进入直播间欢迎大帅哥进入直播间
            </li>
          </ul>

          <div class="send_comment">
            <el-input v-model="sendValue" placeholder="和主播想说点什么…"></el-input>
            <span class="send_comment_span">发送</span>
          </div>
        </div>
      </div>
    </div> -->

      <div class="video">
        <iframe
          :src="path"
          width="100%"
          height="100%"
          ref="iframe"
          frameborder="0"
        ></iframe>
      </div>
    <div class="tuijian">
      <div class="body_title">
        <h4>推荐商品</h4>
      </div>
      <div class="showbody">
        <ul class="showul">
          <li class="showli"
          v-for="(item,index) in yushouList" :key="index">
            <div class="yscont"
            @click="toDetails(item)">
              <span class="yushouCont">预售</span>
              <img :src="item.pic" alt="">
              <p class="p1 ptitle">
                {{item.name}}
              </p>
              <p class="p1">
                <span>
                  <i class="zititi c1">￥</i>{{item.price}}
                  <i class="zititi">/{{item.unit}}</i>
                </span>
              </p>
              <!-- <p class="zititi">转售数：<i class="p2">{{item.number}}</i></p> -->
              <p class="zititi">截至日期：<i class="p2">{{item.offerExpiryDate}}</i></p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <menu-list></menu-list>

  <div id="foot">
    <Footer/>
  </div>
</div>
</template>

<script>
import { reactive, ref } from 'vue-demi'
import HeaderBase from '../../components/HeaderBase.vue'
// import { Search } from '@element-plus/icons-vue'
import Footer from '../index/Footer.vue'
import MenuList from './menuList.vue'
import http from '../../api/http'
import { ElMessage } from 'element-plus'
import { getStore } from '../../tools/storage'
// import { getStore } from '../../tools/storage'

// 获取直播链接
const getStandardRoomJumpUrl = (data) => {
  return http.post('/mall-portal/aliyunLive/getStandardRoomJumpUrl', data)
}
// 获取直播人信息
const queryBasedOnMerchantId = (data) => {
  return http.get('/mall-portal/aliyunLive/queryMerchantLiveBroadcastInformationBasedOnMerchantId', data)
}
// 获取直播人商品
const search = (data) => {
  return http.get('/mall-portal/product/search', data)
}
// 关注店铺
const followTheStore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/followTheStore', data)
}
// 取消关注店铺
const unpmsfollowthestore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/deleteByMerchantId', data)
}
// 点赞或取消点赞店铺
const likeOrUnlikeAStore = (data) => {
  return http.post('/mall-portal/aliyunLive/likeOrUnlikeAStore', data)
}
export default {
  name: 'LiveDetail',
  data () {
    return {
      sendValue: ref(''), // 输入框内容
      liveUserMes: ref(''), // 主播信息
      userConcern: ref(''), // 用户是否已关注（0->未关注，1->已关注）
      likeOrNot: ref(''), // 用户是否已点赞（0->未点赞，1->已点赞）

      yushouList: reactive([]), // 展示数据
      bizId: ref(''), // 传来的bizId
      anchorId: ref(''), // 传来的anchorId
      path: ref('') // 直播间链接
    }
  },
  components: { HeaderBase, Footer, MenuList },
  methods: {
    toDetails (data) { // 推荐商品
      // console.log(data)
      // this.$emit('dataToDetail', data)
      this.$router.push({
        path: '/reservation/varietiy_details',
        query: {
          productSn: data.productSn
        }
      })
    },
    async initData () { // 初始化直播信息
      console.log(this.bizId)
      const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null

      const data = {
        bizId: this.bizId,
        businessOrUser: 1,
        userId: userId ? userId.id : null
      }
      const res = await getStandardRoomJumpUrl(data)
      // console.log(res)
      if (res.status === 200) {
        this.path = res.data.body.result.standardRoomJumpUrl
        // window.open(res.data.data.body.result.standardRoomJumpUrl, '_blank')
      }
    },
    async userGuanZhu (item) { // 关注取关店铺
      console.log(item)
      const data = {
        merchantId: item.id
      }
      if (this.userConcern === 0) {
        const res = await followTheStore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.userConcern = 1
          this.liveUserMes.focusOn = 1
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      } else {
        // data = [70]
        const res = await unpmsfollowthestore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.userConcern = 0
          this.liveUserMes.focusOn = 0
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      }
    },
    async userLivetoUser () { // 获直播信息
      const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
      // console.log(userId)
      const data = {
        merchantId: this.anchorId,
        userId: userId ? userId.id : null
      }
      const res = await queryBasedOnMerchantId(data)
      console.log(res)
      if (res.status === 200) {
        this.liveUserMes = res.data
        this.userConcern = this.liveUserMes.focusOn
        this.likeOrNot = this.liveUserMes.likeOrNot
      }
    },
    async userLiveorder () { // 获取直播商品
      const data = {
        merchantId: this.anchorId,
        previewStatus: 1,
        pageSize: 8,
        pageNum: 1
      }
      const res = await search(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.yushouList = res.data.data.list
      }
    },
    async linkStore () { // 点赞或取消点赞店铺
      let data = {}
      if (this.likeOrNot === 0) {
        data = {
          likeOrUnlike: 0,
          merchantId: this.anchorId
        }
        const res = await likeOrUnlikeAStore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.likeOrNot = 1
        }
      } else {
        data = {
          likeOrUnlike: 1,
          merchantId: this.anchorId
        }
        const res = await likeOrUnlikeAStore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.likeOrNot = 0
        }
      }
    }
  },
  mounted () {
    this.bizId = this.$route.query.bizId
    this.anchorId = this.$route.query.anchorId
    // this.userId = getStore()
    this.initData()
    this.userLivetoUser()
    this.userLiveorder()
  },
  setup (props, ctx) {
    return {
    }
  }
}
</script>
<style lang='scss' scoped>
@import '../../style/viriables.scss';

.live_main{
  background: #f4f0ed;
  font-family: Microsoft YaHei;
  font-weight: 400;
  .live_msg{
    width: 1200px;
    margin: 0 auto;
    .top{
      background: #F3F3F3;
      display: flex;
      justify-content: space-between;
      .user{
        display: flex;
        box-sizing: border-box;
        padding: 20px 0;
        img{
          width: 180px;
          height: 150px;
        }
        h3{
          margin-left: 20px;
          line-height: 60px;
          .name{
            font-size: 27px;
            font-weight: bold;
            color: #8F4B1C;
          }
          .fensi{
            color: #333333;
            font-size: 25px;
          }
        }
      }
      .user_setting{
        box-sizing: border-box;
        padding: 36px 10px;
        width: 280px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        .el-button{
          width: 124px;
          height: 47px;
          border-radius: 10px;
        }
        .look{
          color: #606165;
          font-size: 25px;
        }
        .star{
          font-size: 25px;
          // color: #606165;
        }
        .el-icon{
          font-size: 25px;
          // color: #606165;
        }
      }
    }

    .video{
      display: flex;
      height: 730px;
      // .title_left{
      //   margin: 10px 0;
      //   display: flex;
      //   width: 900px;
      //   height: 710px;
      //   align-items: center;
      //   justify-content: center;
      //   background: #000000;
      //   .el-button{
      //     width: 284px;
      //     height: 75px;
      //     // background: #EB6100;
      //     border-radius: 15px;
      //   }
      // }
      // .title_right{
      //   display: flex;
      //   margin: 10px 0;
      //   flex-flow: column;
      //   .title_msg{
      //     background: #DCD8D8;
      //     box-sizing: border-box;
      //     padding: 14px;
      //     width: 300px;
      //     height: 137px;
      //     font-size: 21px;
      //     font-family: SimHei;
      //     color: #666666;
      //     line-height: 27px;
      //     text-align: center;
      //   }
      //   .comment_body{
      //     flex: 1;
      //     display: flex;
      //     flex-wrap: wrap;
      //     width: 300px;
      //     background: #FFFFFF;
      //     .test_ul{
      //       padding: 20px 10px;
      //       height: 527px;
      //       box-sizing: border-box;
      //       .test_li{
      //         line-height: 37px;
      //         font-size: 21px;
      //         font-family: SimHei;
      //         text-overflow: -o-ellipsis-lastline;
      //         overflow: hidden;
      //         text-overflow: ellipsis;
      //         display: -webkit-box;
      //         -webkit-line-clamp: 3;
      //         -webkit-box-orient: vertical;
      //         .user_name{
      //           color: #EE7A29;
      //         }
      //       }
      //     }
      //     .send_comment{
      //       flex: 1;
      //       display: flex;
      //       align-items: flex-end;
      //       .send_comment_span{
      //         background: #FD6B00;
      //         border: 2px solid #A0A0A0;
      //         width: 63px;
      //         height: 36px;
      //         text-align: center;
      //         color: #FFFFFF;
      //         cursor: pointer;
      //         line-height: 36px;
      //       }
      //     }
      //   }
      // }
    }
    .tuijian{
      display: flex;
      flex-flow: column;
      .body_title{
        height: 60px;
        padding: 0 20px;
        background: #DCD8D8;
        border: 1px solid #595959;
        h4{
          line-height: 60px;
          font-size: 29px;
          font-weight: bold;
          color: #000000;
        }
      }
      .showbody{
        width: 100%;
        flex: 1;
        .showul{
          .showli{
            float: left;
            width: 300px;
            box-sizing: border-box;
            padding: 20px;
            background: $divbgColor;
            border-right: 1px solid $bgColor;
            border-bottom: 1px solid $bgColor;
            .yscont{
              position: relative;
              cursor: pointer;
              .yushouCont{
                width: 30px;
                height: 20px;
                font-size: 12px;
                color: #ffffff;
                text-align: center;
                line-height: 20px;
                background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
                border-radius: 0px 0px 4px 0px;
                position: absolute;
              }
              p{
                line-height: 35px;
              }
              img{
                width: 260px;
                height: 260px;
              }
              .p1{
                color: #333333;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                width: 260px;
                span{
                  display: inline-block;
                  // float: right;
                  text-align: right;
                  color: #c88756;
                  font-size: 20px;
                  .c1{
                    color: #c88756;
                  }
                }
              }
              .ptitle{
                line-height: 21px;
                margin: 10px 0;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
              .zititi{
                color: #999999;
                font-size: 14px;
                .p2{
                  color: #333333;
                }
              }
            }
          }
        }
      }
    }
  }
  #foot{
    background: #280E01;
  }
}
</style>
